import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./StaticContents";

const StaticContents = lazy(() => import("./StaticContents"));

export default (props: Props) => (
  <Page>
    <StaticContents {...props} />
  </Page>
);
