import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./Customers";

const Accounts = lazy(() => import("./Customers"));

export default (props: Props) => (
  <Page>
    <Accounts {...props} />
  </Page>
);