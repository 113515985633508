import { AxiosError, AxiosRequestConfig } from "axios";
import { produce } from "immer";
import { ActionsObservable, combineEpics, ofType } from "redux-observable";
import { createSelector } from "reselect";
import { from, Observable, of } from "rxjs";
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
} from "rxjs/operators";
import api, { ApiResponse } from "../api";
import { endpoints } from "config";
import {
  deleteTFAType,
  deleteTokenResource,
  LoadedTokenResource,
  loadTFAType,
  loadTokenResource,
  saveTFAType,
  saveTokenResource,
  Scopes,
} from "../helpers/auth";

import { Store } from "./rootReducer";
import { deleteUserInfo, updateUserInfo } from "./userInfo";

export type SignInAttempt = {
  username: string;
  password: string;
  rememberMe: boolean;
};

export type TFASignInAttempt = {
  accountType: number;
  username: string;
  password: string;
  rememberMe: boolean;
  tfaCode: string;
  tfaType: string;
  phoneNumber?: string;
  totpSharedSecret?: string;
};

type SignInError = {
  error: null | string;
  error_description: string;
  error_uri: null;
};

type SignInSuccess = {
  details: {
    accessToken: string;
    bEmailVerified: true;
    bTwoFactorAppAuthEnabled: false;
    bTwoFactorSMSAuthEnabled: false;
    expiresIn: string;
    refreshToken: string;
    refreshTokenExpiresIn: number;
    scopes?: Scopes;
  };
  status: string;
};

type SignInNeedsTFA = {
  details: {
    accessToken: null;
    bEmailVerified: boolean;
    bTwoFactorAppAuthEnabled: boolean;
    bTwoFactorSMSAuthEnabled: boolean;
    expiresIn: 0;
    refreshToken: null;
    scopes?: Scopes;
  };
  status: string;
};
type SignInNeedsEmailVerified = {
  details: {
    accessToken: null;
    bEmailVerified: false;
    bTwoFactorAppAuthEnabled: boolean;
    bTwoFactorSMSAuthEnabled: boolean;
    expiresIn: 0;
    refreshToken: null;
    refreshTokenExpiresIn: 0;
    scopes?: Scopes;
  };
  status: string;
};
type SignInNeedsToAddTFAToAccount = {
  details: {
    accessToken: null;
    bEmailVerified: true;
    bTwoFactorAppAuthEnabled: false;
    bTwoFactorSMSAuthEnabled: false;
    expiresIn: 0;
    refreshToken: null;
    refreshTokenExpiresIn: 0;
    scopes?: Scopes;
  };
  status: string;
};

type SignInResponse =
  | SignInSuccess
  | SignInError
  | SignInNeedsTFA
  | SignInNeedsEmailVerified;

type TFATypesResponse = {
  bTwoFactorAppAuthEnabled: boolean;
  bTwoFactorSMSAuthEnabled: boolean;
};

// Type guards to check response type.
const isSignInResponseSuccess = (r: SignInResponse): r is SignInSuccess =>
  (r as SignInSuccess).status === "1" &&
  !!(r as SignInSuccess).details.accessToken;

const isResponseNeedsTFA = (r: SignInResponse): r is SignInNeedsTFA => {
  const response = r as SignInNeedsTFA;
  return (
    (response.details.bTwoFactorAppAuthEnabled ||
      response.details.bTwoFactorSMSAuthEnabled) &&
    !response.details.accessToken
  );
};
const isResponseEmailUnverified = (
  r: SignInResponse
): r is SignInNeedsEmailVerified => {
  return !(r as SignInNeedsEmailVerified).details.bEmailVerified;
};
const isResponseNeedsToAddTFAToAccount = (
  response: SignInResponse
): response is SignInNeedsToAddTFAToAccount => {
  const r = response as SignInNeedsToAddTFAToAccount;
  return (
    r.details.bEmailVerified &&
    !r.details.bTwoFactorAppAuthEnabled &&
    !r.details.bTwoFactorSMSAuthEnabled
  );
};

/* STATE */
type AuthStatus =
  | "signed_out"
  | "signed_in"
  | "need_tfa"
  | "need_email_verified"
  | "needs_to_add_account_tfa"
  | "pending"
  | "tfa_pending"
  | "tfa_setup_complete";
export type TFA = "sms" | "app" | "both";

type State = {
  appVisible: boolean;
  authStatus: AuthStatus;
  TFAType: TFA | null;
  accessTokenNeedsRefresh: boolean;
  message: string;
};

const initialState: State = {
  authStatus: "signed_out",
  appVisible: false,
  TFAType: null,
  accessTokenNeedsRefresh: false,
  message: "",
};

const getTFAType = (payload: SignInNeedsTFA | SignInSuccess): TFA | null => {
  if (
    payload.details.bTwoFactorAppAuthEnabled &&
    payload.details.bTwoFactorSMSAuthEnabled
  ) {
    return "both";
  }
  if (payload.details.bTwoFactorAppAuthEnabled) {
    return "app";
  }
  if (payload.details.bTwoFactorSMSAuthEnabled) {
    return "sms";
  }
  return null;
};

/* ACTIONS */

const SIGN_OUT = "app/auth/SIGN_OUT";
const SIGN_IN = "app/auth/SIGN_IN";
const TFA_SIGN_IN = "app/auth/TFA_SIGN_IN";
const ENABLE_TFA_SIGN_IN = "app/auth/ENABLE_TFA_SIGN_IN";
const SIGN_IN_SUCCESS = "app/auth/SIGN_IN_OKAY";
const SIGN_IN_NEEDS_TFA = "app/auth/SIGN_IN_NEED_TFA";
const SIGN_IN_NEEDS_EMAIL_VERIFIED = "app/auth/SIGN_IN_NEED_EMAIL_VERIFIED";
const SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT =
  "SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT";
const SIGN_IN_ERROR = "app/auth/SIGN_IN_ERROR";
const TFA_SIGN_IN_ERROR = "app/auth/TFA_SIGN_IN_ERROR";
const ENABLE_TFA_SIGN_IN_ERROR = "app/auth/ENABLE_TFA_SIGN_IN_ERROR";
const SHOW_APP = "app/auth/SHOW_APP";
const CHECK_SIGNED_IN = "app/auth/CHECK_SIGNED_IN";
const REFRESH_ACCESS_TOKEN = "app/auth/REFRESH_ACCESS_TOKEN";
const SET_ACCESS_TOKEN_REFRESHED = "app/auth/SET_ACCESS_TOKEN_REFRESHED";
const GET_USER_TFA_SETTING = "app/auth/GET_USER_TFA_SETTINGS";
const SET_USER_TFA_SETTING = "app/auth/SET_USER_TFA_SETTINGS";
const SET_AUTH_STATUS = "app/auth/SET_AUTH_STATE";
const SET_TFA_TYPE = "app/auth/SET_TFA_TYPE";

type Action =
  | { type: typeof SIGN_IN; payload: SignInAttempt }
  | { type: typeof TFA_SIGN_IN; payload: TFASignInAttempt }
  | { type: typeof ENABLE_TFA_SIGN_IN; payload: TFASignInAttempt }
  | { type: typeof SIGN_OUT; message: string; signOutMarker: boolean }
  | { type: typeof CHECK_SIGNED_IN }
  | { type: typeof SHOW_APP; payload: boolean }
  | { type: typeof SIGN_IN_SUCCESS; payload: SignInSuccess }
  | { type: typeof SIGN_IN_NEEDS_TFA; payload: SignInNeedsTFA }
  | {
      type: typeof SIGN_IN_NEEDS_EMAIL_VERIFIED;
      payload: SignInNeedsEmailVerified;
    }
  | { type: typeof SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT }
  | { type: typeof SIGN_IN_ERROR; payload: SignInError }
  | { type: typeof TFA_SIGN_IN_ERROR; payload: SignInError }
  | { type: typeof ENABLE_TFA_SIGN_IN_ERROR; payload: SignInError }
  | { type: typeof REFRESH_ACCESS_TOKEN }
  | { type: typeof SET_ACCESS_TOKEN_REFRESHED }
  | { type: typeof GET_USER_TFA_SETTING }
  | { type: typeof SET_USER_TFA_SETTING; payload: TFATypesResponse }
  | { type: typeof SET_AUTH_STATUS; payload: AuthStatus }
  | { type: typeof SET_TFA_TYPE; payload: TFA | null };

/* REDUCER */

export default function reducer(state = initialState, action: Action): State {
  return produce(state, draft => {
    switch (action.type) {
      case SHOW_APP:
        draft.appVisible = true;
        // Is the user already signed in?
        if (action.payload === true) {
          draft.authStatus = "signed_in";
        }
        break;
      case SIGN_OUT:
        draft.authStatus = "signed_out";
        draft.message = action.message;
        break;
      case SIGN_IN:
        draft.authStatus = "pending";
        draft.message = "";
        break;
      case TFA_SIGN_IN:
        draft.authStatus = "tfa_pending";
        draft.message = "";
        break;
      case ENABLE_TFA_SIGN_IN:
        draft.authStatus = "tfa_pending";
        draft.message = "";
        break;
      case SIGN_IN_SUCCESS:
        draft.authStatus = "signed_in";
        draft.TFAType = getTFAType(action.payload);
        saveTFAType(draft.TFAType);
        break;
      case SIGN_IN_NEEDS_TFA:
        draft.authStatus = "need_tfa";
        draft.TFAType = getTFAType(action.payload);
        saveTFAType(draft.TFAType);
        break;
      case SIGN_IN_NEEDS_EMAIL_VERIFIED:
        draft.authStatus = "need_email_verified";
        break;
      case SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT:
        draft.authStatus = "needs_to_add_account_tfa";
        break;
      case SIGN_IN_ERROR:
        draft.authStatus = "signed_out";
        draft.message = action.payload.error_description;
        break;
      case TFA_SIGN_IN_ERROR:
        draft.authStatus = "need_tfa";
        draft.message = action.payload.error_description;
        break;
      case ENABLE_TFA_SIGN_IN_ERROR:
        draft.authStatus = "needs_to_add_account_tfa";
        draft.message = action.payload.error_description;
        break;
      case REFRESH_ACCESS_TOKEN:
        draft.accessTokenNeedsRefresh = true;
        break;
      case SET_ACCESS_TOKEN_REFRESHED:
        draft.accessTokenNeedsRefresh = false;
        break;
      case SET_USER_TFA_SETTING:
        const {
          bTwoFactorAppAuthEnabled,
          bTwoFactorSMSAuthEnabled,
        } = action.payload;
        if (bTwoFactorAppAuthEnabled && bTwoFactorSMSAuthEnabled) {
          draft.TFAType = "both";
        } else if (bTwoFactorAppAuthEnabled) {
          draft.TFAType = "app";
        } else if (bTwoFactorSMSAuthEnabled) {
          draft.TFAType = "sms";
        }
        saveTFAType(draft.TFAType);
        break;
      case SET_TFA_TYPE:
        draft.TFAType = action.payload;
        break;
      case SET_AUTH_STATUS:
        draft.authStatus = action.payload;
    }
  });
}

/* EPICS */

function makeRequest<T>(config: AxiosRequestConfig) {
  return from(api.request<T>(config));
}

export function saveResponse(r: SignInResponse) {
  if (isSignInResponseSuccess(r)) {
    const {
      accessToken,
      refreshToken,
      refreshTokenExpiresIn,
      scopes,
    } = r.details;
    saveTokenResource({
      accessToken,
      refreshToken,
      refreshTokenExpiresIn,
      scopes: scopes ?? [],
    });
  }
}

export const checkResponse = (r: SignInResponse) => {
  if (isResponseEmailUnverified(r)) {
    return signInNeedsEmailVerified(r);
  }
  if (isResponseNeedsToAddTFAToAccount(r)) {
    return signInNeedsToAddTFAToAccount(r);
  }
  if (isResponseNeedsTFA(r)) {
    return signInNeedsTFA(r);
  }
  if (isSignInResponseSuccess(r)) {
    return signInSuccess(r);
  }
  return signInError(r);
};

// const parseApiErrorMessageCode = (code: string): string => {
//   switch (code) {
//     case "Invalid_User":
//       return "Invalid";
//     default:
//       return "Oops, something went wrong. Please try again later.";
//   }
// };

const parseApiErrorMessageCode = (code: string): string => {
  switch (code) {
    case "Username_Or_Password_Incorrect":
      return "Invalid username or password";
    case "Account_Locked":
      return "Your account has been locked after too many failed attempts. Please try again in 10 minutes.";
    case "Account_Suppressed":
      return "Your account is currently inactive.";
    case "Invalid":
      return "Code is invalid, please try again";
    default:
      return "Oops, something went wrong. Please try again later.";
  }
};

const clearSignOutAlert = () => {
  if (window && window.localStorage.getItem("signOutAlert") !== null)
    window.localStorage.removeItem("signOutAlert");
};

/** Attempt to sign in and handle response, cancel if we sign out while a request pending. */
const signInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
  action$.pipe(
    ofType(SIGN_IN),
    switchMap(attempt =>
      makeRequest<SignInResponse>({
        url: endpoints.auth.login,
        data: attempt.payload,
        method: "post",
      }).pipe(
        tap(res => saveResponse(res.data)),
        tap(clearSignOutAlert), // clear signOutAlert if it still exists
        map(res => checkResponse(res.data)),
        catchError((err: AxiosError) => {
          console.log("err", err);
          const errorMessage = parseApiErrorMessageCode(
            err.response?.data.errors &&
              err.response.data.errors.length &&
              err.response.data.errors.length > 0
              ? err.response?.data.errors[0].messageCode
              : ""
          );
          return of(
            signInError({
              error: null,
              error_description: `${errorMessage}`,
              error_uri: null,
            })
          );
        })
        //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
      )
    )
  );
/** Attempt to tfa sign in and handle response, cancel if we sign out while a request pending. */
const tfaSignInEpic = (action$: ActionsObservable<any>): Observable<Action> =>
  action$.pipe(
    ofType(TFA_SIGN_IN),
    switchMap(attempt =>
      makeRequest<SignInResponse>({
        url: endpoints.auth.tfalogin,
        data: attempt.payload,
        method: "post",
      }).pipe(
        tap(res => saveResponse(res.data)),
        map(res => checkResponse(res.data)),
        catchError((err: AxiosError) => {
          console.log("err", err);
          const errorMessage = parseApiErrorMessageCode(
            err.response?.data.errors &&
              err.response.data.errors.length &&
              err.response.data.errors.length > 0
              ? err.response?.data.errors[0].messageCode
              : ""
          );
          return of(
            tfaSignInError({
              error: null,
              error_description: `${errorMessage}`,
              error_uri: null,
            })
          );
        })
        //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
      )
    )
  );

const enableTfaSignInEpic = (
  action$: ActionsObservable<any>
): Observable<Action> =>
  action$.pipe(
    ofType(ENABLE_TFA_SIGN_IN),
    switchMap(attempt =>
      makeRequest<SignInResponse>({
        url: endpoints.auth.enableTfa,
        data: attempt.payload,
        method: "post",
      }).pipe(
        filter(res => res.status === 200),
        map(res => setAuthStatus("tfa_setup_complete")),
        catchError((err: AxiosError) => {
          console.log("err", err);
          const errorMessage = parseApiErrorMessageCode(
            err.response?.data.errors &&
              err.response.data.errors.length &&
              err.response.data.errors.length > 0
              ? err.response?.data.errors[0].messageCode
              : ""
          );
          return of(
            enableTfaSignInError({
              error: null,
              error_description: `${errorMessage}`,
              error_uri: null,
            })
          );
        })
        //takeUntil(action$.pipe(ofType(SIGN_OUT, SIGN_IN_ERROR)))
      )
    )
  );

/** Clear the local storage on sign out, so we don't appear logged in on refresh. */
const signOutEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(SIGN_OUT),
    tap(action => {
      window && deleteTokenResource();
      window && deleteTFAType();
      if (action.signOutMarker) {
        window && window.localStorage.setItem("signOutAlert", "true");
      }
    }),
    map(deleteUserInfo)
  );

const checkSignedInEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(
    ofType(CHECK_SIGNED_IN),
    map(loadTokenResource),
    map(r => r !== null),
    map(showApp)
  );
const checkTFATypeEpic = (action$: ActionsObservable<any>) =>
  action$.pipe(ofType(CHECK_SIGNED_IN), map(loadTFAType), map(setTFAType));

const saveFetchedTokenResource = (r: any) => {
  const { accessToken, refreshToken, scopes, refreshTokenExpiresIn } = r;
  saveTokenResource({
    accessToken,
    refreshToken,
    refreshTokenExpiresIn,
    scopes: scopes ?? [],
  });
};

const refreshAccessTokenEpic = (action$: ActionsObservable<Action>) =>
  action$.pipe(
    ofType(REFRESH_ACCESS_TOKEN),
    switchMap(() => {
      const resource = loadTokenResource() as LoadedTokenResource;
      return makeRequest<ApiResponse<SignInResponse>>({
        url: endpoints.auth.refreshAccessToken,
        method: "POST",
        data: {
          accessToken: resource.accessToken,
          refreshToken: resource.refreshToken,
        },
      }).pipe(
        filter(response => response.data.status === "1"),
        map(response => response.data.details),
        mergeMap(response => {
          saveFetchedTokenResource(response);
          return [{ type: SET_ACCESS_TOKEN_REFRESHED }, updateUserInfo()];
        })
      );
    })
  );

// const getUserTFASettingsEpic = (action$: ActionsObservable<Action>) =>
//     action$.pipe(
//         ofType(GET_USER_TFA_SETTING),
//         switchMap(() => {
//             return makeRequest<ApiResponse<TFATypesResponse>>({
//                 url: endpoints.profilemodule.usersecurityinfo,
//             }).pipe(
//                 filter((response) => {
//                     return response.data.status === '1';
//                 }),
//                 map((response) => {
//                     return setUserTFASetting(response.data.details);
//                 })
//             );
//         })
//     );

export const authEpic = combineEpics(
  signInEpic,
  tfaSignInEpic,
  enableTfaSignInEpic,
  checkSignedInEpic,
  refreshAccessTokenEpic,
  checkTFATypeEpic,
  // getUserTFASettingsEpic
  signOutEpic
);

/* ACTION CREATORS */

export const signIn = (payload: SignInAttempt): Action => {
  return {
    type: SIGN_IN,
    payload,
  };
};

export const tfaSignIn = (payload: TFASignInAttempt): Action => ({
  type: TFA_SIGN_IN,
  payload,
});

export const enableTfaSignIn = (payload: TFASignInAttempt): Action => ({
  type: ENABLE_TFA_SIGN_IN,
  payload,
});

export const signOut = (message = "", marker = true): Action => ({
  type: SIGN_OUT,
  message,
  signOutMarker: marker,
});

export const checkSignedIn = (): Action => ({
  type: CHECK_SIGNED_IN,
});

const signInSuccess = (payload: SignInSuccess): Action => ({
  type: SIGN_IN_SUCCESS,
  payload,
});

export const signInNeedsTFA = (payload: SignInNeedsTFA): Action => ({
  type: SIGN_IN_NEEDS_TFA,
  payload,
});
export const signInNeedsEmailVerified = (
  payload: SignInNeedsEmailVerified
): Action => ({
  type: SIGN_IN_NEEDS_EMAIL_VERIFIED,
  payload,
});
export const signInNeedsToAddTFAToAccount = (
  payload: SignInNeedsToAddTFAToAccount
): Action => ({
  type: SIGN_IN_NEEDS_TO_ADD_TFA_TO_ACCOUNT,
});
export const refreshAccessToken = () => ({ type: REFRESH_ACCESS_TOKEN });
export const getUserTFASettings = () => ({ type: GET_USER_TFA_SETTING });
export const setUserTFASetting = (payload: TFATypesResponse) => ({
  type: SET_USER_TFA_SETTING,
  payload,
});

export const setAuthStatus = (payload: AuthStatus): Action => ({
  type: SET_AUTH_STATUS,
  payload,
});

const signInError = (payload: SignInError): Action => ({
  type: SIGN_IN_ERROR,
  payload,
});
const tfaSignInError = (payload: SignInError): Action => ({
  type: TFA_SIGN_IN_ERROR,
  payload,
});
const enableTfaSignInError = (payload: SignInError): Action => ({
  type: ENABLE_TFA_SIGN_IN_ERROR,
  payload,
});

const setTFAType = (payload: TFA | null): Action => ({
  type: SET_TFA_TYPE,
  payload,
});

const showApp = (payload: boolean): Action => ({
  type: SHOW_APP,
  payload,
});

/* SELECTORS */

export const selectAuthStatus = (app: Store) => app.auth.authStatus;
export const selectSignedIn = createSelector(
  selectAuthStatus,
  status => status === "signed_in"
);
export const selectAuthMessage = (app: Store) => app.auth.message;
export const selectTFAType = (app: Store) => app.auth.TFAType;
export const selectAppVisible = (app: Store) => app.auth.appVisible;
export const selectAccessTokenNeedsRefresh = (app: Store) =>
  app.auth.accessTokenNeedsRefresh;
// Selects the TFAType from the store, which is 'sms' | 'app' | 'both'.
// These values aren't used anywhere outside this reducer so unlikely you'll need.
export const selectActiveTFAType = (app: Store) => app.auth.TFAType;
