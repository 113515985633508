import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./FrozenTransactions";

const FrozenTransfers = lazy(() => import("./FrozenTransactions"));

export default (props: Props) => (
  <Page>
    <FrozenTransfers {...props} />
  </Page>
);