import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./VenueUsers";

const VenueUsers = lazy(() => import("./VenueUsers"));

export default (props: Props) => (
  <Page>
    <VenueUsers {...props} />
  </Page>
);
