import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectSignedIn } from "reducers/auth";
import { selectUserInfo, updateUserInfo } from "reducers/userInfo";

export const useGetUserInfo = () => {
  const dispatch = useDispatch();
  const isSignedIn = useSelector(selectSignedIn);
  const userInfo = useSelector(selectUserInfo);

  // Make fetch for customer component resources if logged-in and not present.
  useEffect(() => {
    if (isSignedIn && !userInfo.userInfo) {
      dispatch(updateUserInfo());
    }
  }, [dispatch, isSignedIn, userInfo.userInfo]);
};
