import React from "react";
import { useDispatch } from "react-redux";
import { signOut } from "reducers/auth";

export function useSignOutAcrossTabs() {
  const dispatch = useDispatch();

  React.useEffect(() => {
    function signOutIfMarker() {
      if (localStorage.getItem("signOutAlert") != null) {
        localStorage.removeItem("signOutAlert");
        dispatch(signOut("You have been signed out.", false));
      }
    }

    window.addEventListener("storage", signOutIfMarker);

    return () => {
      window.removeEventListener("storage", signOutIfMarker);
    };
  }, [dispatch]);
}
