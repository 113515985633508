import { useOnClickOutside } from "@avamae/hooks";
import { Link } from "@reach/router";
import React from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "reducers/auth";
import { selectHeaderMenuOpen, setHeaderMenuOpen } from "reducers/header";

const HeaderMenu = () => {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectHeaderMenuOpen);
  const bind = useOnClickOutside(() => isOpen && dispatch(setHeaderMenuOpen()));

  const handleClick = () => dispatch(signOut());

  const portalTarget = document.getElementById("modal-portal");

  if (isOpen && portalTarget)
    return createPortal(
      <div
        className="HeaderMenu"
        {...bind}
        onClick={() => dispatch(setHeaderMenuOpen())}
      >
        <ul>
          <li>
            <Link to={"/account"}>Account</Link>
          </li>
          <li className="ClickableItem" onClick={handleClick}>
            Sign out
          </li>
        </ul>
      </div>,
      portalTarget
    );
  return null;
};

export default HeaderMenu;
