import React from "react";
import { useSelector } from "react-redux";
import { Redirect } from "@reach/router";
import { selectAuthStatus } from "reducers/auth";

const Private: React.FC = ({ children }) => {
  const authStatus = useSelector(selectAuthStatus);

  if (authStatus === "signed_out") return <Redirect noThrow to="/sign-in" />;

  return <React.Fragment>{children}</React.Fragment>;
};

export default Private;
