import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./Account";

const Account = lazy(() => import("./Account"));

export default (props: Props) => (
  <Page>
    <Account {...props} />
  </Page>
);
