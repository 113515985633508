import React, { useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectSignedIn, signOut } from "reducers/auth";
import Button from "components/ui/Button";
import { AccessElement, useLoadScopes } from "helpers/auth";
import { Link, WindowLocation, navigate } from "@reach/router";
import Mobile_02 from "assets/Icon_Mobile_02.png";
import { path, T } from "ramda";
import { useOnClickOutside } from "helpers/CustomUseOnClickOutside";

type Props = {
  mobileNavOpen: boolean;
  closeMobileNav(): void;
  location: WindowLocation;
  hide?: boolean;
};

const addOrAppend = <T,>(
  object: { [s: string]: T[] },
  key: string,
  value: T
) => {
  const existingValue = object[key];
  if (existingValue === undefined) {
    return { ...object, [key]: [value] };
  }

  return { ...object, [key]: existingValue.concat([value]) };
};

const Navigation: React.FC<Props> = ({
  closeMobileNav,
  location,
  mobileNavOpen,
  hide,
}) => {
  const dispatch = useDispatch();
  const handleClick = () => dispatch(signOut());
  const isSignedIn = useSelector(selectSignedIn);
  const accessElements = useLoadScopes();
  const ref = useOnClickOutside(() => mobileNavOpen && closeMobileNav());

  if (!isSignedIn || (hide && !mobileNavOpen)) return null;

  const pathname = location.pathname;

  let groupedElements: { [s: string]: AccessElement[] } = {};
  accessElements.forEach(accessElement => {
    if (accessElement.usageType !== null) {
      groupedElements = addOrAppend(
        groupedElements,
        accessElement.usageType,
        accessElement
      );
    }
  });

  return (
    <>
      <div
        className={`MobileNavBackground ${mobileNavOpen ? "" : "Hidden"}`}
      ></div>
      <nav
        id="MainNavigation"
        className={mobileNavOpen ? "Navigation Open" : "Navigation"}
        ref={ref}
      >
        <div className="Inner">
          <div className="Block">
            {Object.keys(groupedElements).map(group => (
              <ElementGroup
                group={group}
                elements={groupedElements[group]}
                currentPath={pathname}
                closeMobileNav={closeMobileNav}
              />
            ))}
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navigation;

const ElementGroup = (props: {
  group: string;
  elements: AccessElement[];
  currentPath: string;
  closeMobileNav: () => void;
}) => {
  const { group, elements, currentPath, closeMobileNav } = props;
  return (
    <>
      <span className="GroupName">{group}</span>
      <ul>
        {elements.map(e => (
          <TopLevelItem
            key={e.displayName}
            accessElement={e}
            active={currentPath.includes(e.path)}
            closeMobileNav={closeMobileNav}
          />
        ))}
      </ul>
      <div className="LineBreak" />
    </>
  );
};

const TopLevelItem: React.FC<{
  accessElement: AccessElement;
  active: boolean;
  closeMobileNav(): void;
}> = ({ accessElement, active, closeMobileNav }) => {
  return (
    <li className={active ? "Active" : ""}>
      <Link to={accessElement.path} onClick={closeMobileNav}>
        {accessElement.displayName}
      </Link>
    </li>
  );
};
