import React, { Suspense, lazy, useEffect } from "react";
import { useSelector } from "react-redux";
import { selectAppVisible } from "reducers/auth";
import { Router, Location, RouteComponentProps } from "@reach/router";
import ErrorBoundary from "components/ErrorBoundary";
import { useCheckSignedIn } from "helpers/useCheckSignedIn";
import ErrorFallback from "components/fallbacks/ErrorFallback";
import Page from "components/Page";

import { MobileHeader } from "components/navigation/MobileHeader";
import AdminUsers from "pages/AdminUsers";
import StaticContents from "pages/staticContents";

import Navigation from "components/navigation/Navigation";
import { useSignOutAcrossTabs } from "helpers/useSignOutAcrossTabs";
import { Toasts } from "components/Toasts";
import { registerUserLocale } from "helpers/userLocale";
import { Spinner } from "components/Spinner/Spinner";
import MainHeader from "components/navigation/MainHeader";
import useMedia from "use-media";
import Footer from "components/Footer/Footer";
import Customers from "pages/Customers";
import FrozenTransactions from "pages/FrozenTransactions";
import Account from "pages/Account";
import Venues from "pages/venues";
import VenueUsers from "pages/venueUsers";
import VenueApplications from "pages/venueApplications";

/** Standard Components */
const SignIn = lazy(() => import("components/SignIn/SignIn"));
const ForgottenPassword = lazy(() =>
  import("components/ForgottenPassword/ForgottenPassword")
);
const ResetPassword = lazy(() =>
  import("components/ResetPassword/ResetPassword")
);
const SetPassword = lazy(() => import("components/SetPassword/SetPassword"));
const NotFound = lazy(() => import("pages/NotFound"));
const Home = lazy(() => import("pages/Home"));

const NotFoundPage = (props: RouteComponentProps) => (
  <Page isPublic>
    <NotFound {...props} />
  </Page>
);
const SetPasswordPage = (props: RouteComponentProps) => (
  <Page isPublic>
    <SetPassword {...props} />
  </Page>
);

const ResetPasswordPage = (props: RouteComponentProps) => (
  <Page isPublic>
    <ResetPassword location={props.location!} />
  </Page>
);

const HomePage = (props: RouteComponentProps) => (
  <Page>
    <Home {...props} />
  </Page>
);

const ForgottenPasswordPage = (props: RouteComponentProps) => (
  <Page isPublic>
    <ForgottenPassword {...props} />
  </Page>
);

// Array of strings represeting the paths where nav should be hidden.
const HIDE_NAV_ON_PATHS: string[] = [];

/* Main App */
const App: React.FC<RouteComponentProps> = ({ location }) => {
  registerUserLocale();
  const [mobileNavOpen, setMobileNavOpen] = React.useState(false);
  const isSignedIn = useCheckSignedIn();
  const isAppVisible = useSelector(selectAppVisible);

  const pathname = location?.pathname;
  const currentLocationIncludes = (locationStrings: string[]): boolean => {
    if (pathname) {
      for (let i = 0; i < locationStrings.length; i++) {
        const current = locationStrings[i];
        if (pathname.includes(current)) {
          return true;
        }
      }
    }

    return false;
  };
  const navHidden = !isSignedIn || currentLocationIncludes(HIDE_NAV_ON_PATHS);

  const tablet = useMedia({ maxWidth: 1200 });

  useEffect(() => {
    !tablet && setMobileNavOpen(false);
  }, [tablet]);

  // If we sign out here, we should sign out in other open windows.
  useSignOutAcrossTabs();

  // At startup, we check to see if we should display a signin page or the content.
  // Return nothing, so that we don't flash a view of signin if unneccessary.
  if (!isAppVisible) return null;

  const openMobileNav = () => {
    setMobileNavOpen(true);
  };

  return (
    <ErrorBoundary fallback={<ErrorFallback />}>
      <Suspense fallback={<Spinner positionAbsolute />}>
        <Location>
          {({ location }) => (
            <Navigation
              location={location}
              closeMobileNav={() => setMobileNavOpen(false)}
              mobileNavOpen={mobileNavOpen}
              hide={navHidden}
            />
          )}
        </Location>
        <div className={navHidden ? "Main NoNav" : "Main"}>
          {tablet ? (
            <MobileHeader
              setMobileNavOpen={setMobileNavOpen}
              isMobileNavOpen={mobileNavOpen}
            />
          ) : (
            <MainHeader />
          )}
          <Router>
            <HomePage path="/" />
            <AdminUsers path="/adminusers" />
            <Customers path="/customerusers" />
            <FrozenTransactions path="/frozentransactions" />
            <Venues path="/venues" />
            <VenueUsers path="/venueusers" />
            <VenueApplications path="/venuegroups/verificationlist" />
            <Account path="/account" />
            <StaticContents path="/staticcontent" />
            <SignIn path="/sign-in" />
            <ForgottenPasswordPage path="/forgotten-password" />
            <ResetPasswordPage path="/reset-password" />
            <SetPasswordPage path="/set-password" />
            <NotFoundPage default />
          </Router>
          <Footer />
        </div>
      </Suspense>

      <Toasts />
    </ErrorBoundary>
  );
};

export default App;
