import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./VenueApplications";

const VenueApplications = lazy(() => import("./VenueApplications"));

export default (props: Props) => (
  <Page>
    <VenueApplications {...props} />
  </Page>
);
