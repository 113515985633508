import React, { Suspense } from "react";
import Private from "components/Private";
import ErrorBoundary from "components/ErrorBoundary";
import ErrorFallback from "components/fallbacks/ErrorFallback";
import { Spinner } from "components/Spinner/Spinner";

const Page: React.FC<{ isPublic?: boolean }> = ({ children, isPublic }) => {
  if (isPublic)
    return (
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<Spinner positionAbsolute />}>{children}</Suspense>
      </ErrorBoundary>
    );

  return (
    <Private>
      <ErrorBoundary fallback={<ErrorFallback />}>
        <Suspense fallback={<Spinner positionAbsolute />}>{children}</Suspense>
      </ErrorBoundary>
    </Private>
  );
};

export default Page;
