import React from "react";
import { Circle } from "better-react-spinkit";

interface SpinnerProps {
  size?: number;
  color?: string;
  scaleStart?: number;
  scaleEnd?: number;
  positionAbsolute?: boolean;
}

const Spinner: React.FC<SpinnerProps> = ({
  size = 50,
  color = "#067889",
  scaleStart = 0.4,
  scaleEnd = 0.8,
  positionAbsolute,
}) => {
  return (
    <div className={positionAbsolute ? "Spinner PositionAbsolute" : "Spinner"}>
      <Circle
        size={size}
        color={color}
        scaleStart={scaleStart}
        scaleEnd={scaleEnd}
      />
    </div>
  );
};

export { Spinner };
