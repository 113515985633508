import React from "react";
import useMedia from "use-media";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthStatus } from "reducers/auth";

//icons
import Logo from "assets/Logo.png";
import MenuIcon from "assets/Icon_Btn_Menu.png";
import CloseMenuIcon from "assets/Icon_Close_01.png";
import { Link } from "@reach/router";
import { selectHeaderMenuOpen, setHeaderMenuOpen } from "reducers/header";
import { useOnClickOutside } from "@avamae/hooks";
import { useGetUserInfo } from "helpers/useGetUserInfo";
import { selectUserInfo } from "reducers/userInfo";
import HeaderMenu from "./HeaderMenu";

type MobileHeaderProps = {
  setMobileNavOpen(open: boolean): void;
  isMobileNavOpen: boolean;
};

const MobileHeader: React.FC<MobileHeaderProps> = ({
  setMobileNavOpen,
  isMobileNavOpen,
}) => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);

  const isMenuOpen = useSelector(selectHeaderMenuOpen);
  const openHeaderMenu = () => !isMenuOpen && dispatch(setHeaderMenuOpen());

  const isSignedIn = authStatus === "signed_in";

  useGetUserInfo();
  const { userInfo } = useSelector(selectUserInfo);

  return (
    <div className="MobileHeader WidthContent">
      {isSignedIn && (
        <div
          className="BtnMobileNav"
          onClick={() => {
            !isMobileNavOpen && setMobileNavOpen(!isMobileNavOpen);
          }}
        >
          <img src={MenuIcon} alt="Nav" />
        </div>
      )}

      <div className="Logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      {isSignedIn && (
        <div className="User">
          <div className="MenuIcon" onMouseDown={() => openHeaderMenu()}>
            <span>
              {`${userInfo?.firstName[0] ?? ""}${userInfo?.lastName[0] ?? ""}`}
            </span>
            <HeaderMenu />
          </div>
        </div>
      )}
    </div>
  );
};

export { MobileHeader };
