import React, { lazy } from "react";
import Page from "components/Page";
import { Props } from "./AdminUsers";

const AdminUsers = lazy(() => import("./AdminUsers"));

export default (props: Props) => (
  <Page>
    <AdminUsers {...props} />
  </Page>
);
