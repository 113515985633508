//import { useOnClickOutside } from "helpers/useOnClickOutside";
import { useOnClickOutside } from "@avamae/hooks";
import { Link } from "@reach/router";
import { useGetUserInfo } from "helpers/useGetUserInfo";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthStatus } from "reducers/auth";
import { selectHeaderMenuOpen, setHeaderMenuOpen } from "reducers/header";
import { selectUserInfo } from "reducers/userInfo";
//import { selectHeaderMenuOpen, setHeaderMenuOpen } from "reducers/header";
//import HeaderMenu from "./HeaderMenu";

//icons
import Logo from "../../assets/Logo.png";
import HeaderMenu from "./HeaderMenu";

const MainHeader = () => {
  const dispatch = useDispatch();
  const authStatus = useSelector(selectAuthStatus);
  useGetUserInfo();
  const { userInfo } = useSelector(selectUserInfo);
  const isHeaderMenuOpen = useSelector(selectHeaderMenuOpen);

  const openHeaderMenu = () =>
    !isHeaderMenuOpen && dispatch(setHeaderMenuOpen()); //just need to call this to open the menu, clicking anywhere other than on the menu closes it

  const isSignedIn = authStatus === "signed_in";
  return (
    <div className="MainHeader WidthContent">
      <div className="Logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      {isSignedIn && (
        <div className="User">
          <span>
            {userInfo && `${userInfo?.firstName} ${userInfo?.lastName}`}
          </span>

          <div className="MenuIcon" onMouseDown={() => openHeaderMenu()}>
            {/* we use onMouseDown because headerMenu's onClickOutside closes the menu on mousedown */}
            <span>
              {`${userInfo?.firstName[0] ?? ""}${userInfo?.lastName[0] ?? ""}`}
            </span>
            <HeaderMenu />
          </div>
        </div>
      )}
    </div>
  );
};

export default MainHeader;
