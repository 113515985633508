import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { Slide, ToastContainer, ToastContainerProps } from "react-toastify";

const Toasts: React.FC<ToastContainerProps> = props => {
  return (
    <ToastContainer position="bottom-center" transition={Slide} {...props} />
  );
};

export { Toasts };
