type customWindow = typeof window & { env: { BASE_URL: string } };
const envUrl = (window as customWindow).env.BASE_URL;
export const BASE_URL = envUrl;

export const endpoints = {
  auth: {
    login: "/api/v1/publicrole/authmodule/login",
    refreshAccessToken: "/api/v1/publicrole/authmodule/refresh",
    requestpasswordreset:
      "/api/v1/publicrole/forgottenpasswordmodule/requestpasswordreset",
    resetpassword: "/api/v1/publicrole/forgottenpasswordmodule/resetpassword",
    initialisepassword:
      "/api/v1/publicrole/forgottenpasswordmodule/resetpassword",
    tfalogin: "/api/v1/publicrole/authmodule/tfalogin",
    verifyEmailAddress: "/api/v1/publicrole/authmodule/verifyemailaddress",
    requestSmsTfaCode: "/api/v1/publicrole/authmodule/requestsmstfacode",
    requestTotpCode: "/api/v1/publicrole/authmodule/requesttotpqrcode",
    enableTfa: "/api/v1/publicrole/authmodule/enabletfa",
  },
  adminUsers: {
    listusers: "/api/v1/adminrole/adminusersmodule/list",
    viewuser: "/api/v1/adminrole/adminusersmodule/view",
    createUser: "/api/v1/adminrole/adminusersmodule/create",
    edituser: "/api/v1/adminrole/adminusersmodule/edit",
    deleteusers: "/api/v1/adminrole/adminusersmodule/delete",
  },
  staticContent: {
    list: "/api/v1/adminrole/webpagespartsmodule/list",
    create: "/api/v1/adminrole/webpagespartsmodule/create",
    edit: "/api/v1/adminrole/webpagespartsmodule/edit",
    view: "/api/v1/adminrole/webpagespartsmodule/view",
    delete: "/api/v1/adminrole/webpagespartsmodule/delete",
  },
  ckFinderModule: {
    uploadImage: "/api/v1/adminrole/ckfindermodule/uploadimage",
  },
  customers: {
    list: "/api/v1/adminrole/customersmodule/list",
    verify: "/api/v1/adminrole/customersmodule/verify",
    view: "/api/v1/adminrole/customersmodule/view",
  },
  frozenTransactions: {
    listTransactions: "/api/v1/adminrole/frozentransactionsmodule/list",
    transactionDetails: "/api/v1/adminrole/frozentransactionsmodule/view",
    unfreezeTransaction: "/api/v1/adminrole/frozentransactionsmodule/unfreeze",
    assess: "/api/v1/adminrole/frozentransactionsmodule/assess",
  },
  profile: {
    requestSmsTfaCode: "/api/v1/adminrole/profilemodule/requestsmstfacode",
    changePassword: "/api/v1/adminrole/profilemodule/updatepassword",
    updateAppTFA: "/api/v1/adminrole/profilemodule/updateapptfa",
    enableSMSTFA: "/api/v1/adminrole/profilemodule/enablesmstfa",
    disableTFA: "/api/v1/adminrole/profilemodule/disabletfa",
    updateTFAConfirm: "/api/v1/adminrole/profilemodule/updatetfaconfirm",
    userInfo: "/api/v1/adminrole/profilemodule/userinfo",
  },
  venues: {
    list: "/api/v1/adminrole/venuesmodule/list",
    create: "/api/v1/adminrole/venuesmodule/create",
    edit: "/api/v1/adminrole/venuesmodule/edit",
    view: "/api/v1/adminrole/venuesmodule/view",
    delete: "/api/v1/adminrole/venuesmodule/delete",
  },
  venueGroupApplications: {
    list: "/api/v1/adminrole/venuegroupsmodule/verificationlist",
    view: "/api/v1/adminrole/venuegroupsmodule/viewverification",
    verify: "/api/v1/adminrole/venuegroupsmodule/verify",
    reject: "/api/v1/adminrole/venuegroupsmodule/reject",
    getBusinessDoc: "/api/v1/adminrole/venuegroupsmodule/getbusinessdocument",
    getBusinessFormDoc:
      "/api/v1/adminrole/venuegroupsmodule/getbusinessformdocument",
    retriggerVerification: `/api/v1/adminrole/venuegroupsmodule/retriggerform`,
    approveSingleDoc: `/api/v1/adminrole/venuegroupsmodule/verifydocument`,
    rejectSingleDoc: `/api/v1/adminrole/venuegroupsmodule/rejectdocument`,
  },
  venueUsers: {
    list: "/api/v1/adminrole/venueusersmodule/list",
    create: "/api/v1/adminrole/venueusersmodule/create",
    edit: "/api/v1/adminrole/venueusersmodule/edit",
    view: "/api/v1/adminrole/venueusersmodule/view",
    delete: "/api/v1/adminrole/venueusersmodule/delete",
  },
};
