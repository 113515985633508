import { Store } from "./rootReducer";

/*State*/
type State = {
  headerMenuOpen: boolean;
};

const initialState: State = {
  headerMenuOpen: false,
};

/* ACTIONS */
const TOOGLE_HEADER_MENU = "TOOGLE_HEADER_MENU";

type Action = {
  type: typeof TOOGLE_HEADER_MENU;
};

/* REDUCER */
export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case TOOGLE_HEADER_MENU:
      return {
        ...state,
        headerMenuOpen: !state.headerMenuOpen,
      };
    default:
      return state;
  }
}

/* ACTION CREATORS */
export const setHeaderMenuOpen = (): Action => ({
  type: TOOGLE_HEADER_MENU,
});

/* SELECTORS */
export const selectHeaderMenuOpen = (app: Store) => app.header.headerMenuOpen;
