import { getYear } from "date-fns";
import React from "react";
import { useSelector } from "react-redux";
import { selectAuthStatus } from "reducers/auth";

const Footer = () => {
  const authStatus = useSelector(selectAuthStatus);

  if (authStatus === "signed_out")
    return (
      <footer className="WidthContent">
        <span>
          Copyright &#169; {getYear(new Date())} Bitline. All right reserved |
          Website development by <span className="Underlined">AVAMAE</span>
        </span>
      </footer>
    );
  return null;
};

export default Footer;
