import { ApiResponse } from "@avamae/formbuilder/dist/FormBuilder";
import instance from "api";
import { endpoints } from "config";
import { ActionsObservable, ofType } from "redux-observable";
import { from } from "rxjs";
import { catchError, map, switchMap } from "rxjs/operators";
import { signOut } from "./auth";
import { Store } from "./rootReducer";

export interface UserInformation {
  firstName: string;
  lastName: string;
  venueName: string;
  venueLocation: string;
}
/* STATE */
type State = {
  userInfo: UserInformation | null;
};

const initialState: State = {
  userInfo: null,
};

/* ACTIONS */
const SET_USER_INFO = "SET_USER_INFO";
const DELETE_USER_INFO = "DELETE_USER_INFO";

type Action =
  | {
      type: typeof SET_USER_INFO;
      payload: { userInfo: UserInformation };
    }
  | { type: typeof DELETE_USER_INFO };

enum EpicActions {
  UPDATE_USER_INFO = "UPDATE_USER_INFO",
}
type EpicAction = {
  type: EpicActions.UPDATE_USER_INFO;
};

export default function reducer(state = initialState, action: Action): State {
  switch (action.type) {
    case SET_USER_INFO:
      return { ...state, ...action.payload };
    case DELETE_USER_INFO:
      return {
        ...state,
        userInfo: null,
      };
    default:
      return state;
  }
}

type UpdateUserInfoEpicAction = { type: EpicActions.UPDATE_USER_INFO };
export const updateUserInfoEpic = (
  action$: ActionsObservable<UpdateUserInfoEpicAction>
) =>
  action$.pipe(
    ofType(EpicActions.UPDATE_USER_INFO),
    switchMap(() => {
      return from(
        instance.get<ApiResponse<any, UserInformation>>(
          endpoints.profile.userInfo
        )
      ).pipe(
        map(response => setUserInfo(response.data.details)),
        catchError(() => {
          return [
            signOut("We couldn't get your information, please sign in again"),
            deleteUserInfo(),
          ];
        })
      );
    })
  );

/* Action Creators */
const setUserInfo = (userInfo: UserInformation): Action => {
  return {
    type: SET_USER_INFO,
    payload: { userInfo },
  };
};

export const deleteUserInfo = (): Action => {
  return {
    type: DELETE_USER_INFO,
  };
};

export const updateUserInfo = (): EpicAction => {
  return {
    type: EpicActions.UPDATE_USER_INFO,
  };
};

/* SELECTORS */
export const selectUserInfo = (store: Store) => store.userInfo;
