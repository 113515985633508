import { combineReducers } from "redux";
import { combineEpics } from "redux-observable";
import auth, { authEpic } from "reducers/auth";
import userInfo, { updateUserInfoEpic } from "./userInfo";
import header from "reducers/header";

export const rootReducer = combineReducers({ auth, userInfo, header });
export const rootEpic = combineEpics(authEpic, updateUserInfoEpic);

export type Store = ReturnType<typeof rootReducer>;
