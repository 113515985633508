import { createUseFetch } from '@avamae/use-fetch';
import React from 'react';
import { fromPairs, symmetricDifference, toPairs } from 'ramda';
import { AxiosInstance, AxiosRequestConfig } from 'axios';
import {
  Filter,
  serializeFilters,
  deserialiseFilters,
} from '@avamae/parse-filter';

type BaseObject = {
  [k: string]: any;
};

export type ColumnDetail<T = any> = {
  bFilterable: boolean;
  bLocked: boolean;
  bSortable: boolean;
  bVisible: boolean;
  columnKey: keyof T;
  filterMetadata: {
    filterType: string;
    details: null | any;
  };
  labelKey: string;
  labelValue: string;
  orderNumber: number;
  tooltip: null | string;
  type: string;
};

export type TableData<T = any> = {
  id: null | string | number;
  status: string;
  errors: any[];
  details: {
    listData: T[];
    filters: string;
    sortBy: string;
    errors: any[];
    columns: ColumnDetail<T>[];
    metadata: any;
    pageNumber: number;
    pageSize: number;
    resultsCount: number;
    searchString: null | string;
    summaryRows: any[];
  };
};

type ColumnSortData<T> = {
  [K in keyof T]?: 'ASC' | 'DESC';
};

export type TableOptions = {
  url: string;
  sortBy?: string;
  filters?: string;
  search?: string;
  pageNumber?: number;
  pageSize?: number;
  bClearFilters?: boolean;
  bClearSortBy?: boolean;
  bClearSearch?: boolean;
  multiSort?: boolean;
  queryParams?: { [k: string]: any };
  preventRequest?: boolean;
};

export type UpdateDetails = {
  EditDate: string;
  ExternalID: string;
  Id: number;
  LastUpdatedBy: string;
  SSEDetails: string;
  bSuppressed: boolean;
};

export type UpdateResponse = {
  details: UpdateDetails;
  errors: Error[];
  id: number;
  status: string;
};

function serialiseSortData<T>(data: ColumnSortData<T>): string {
  const keys = Object.keys(data);
  const values = keys.map(k => `${k} ${data[k as keyof T]}`);
  return values.join(' | ');
}

function deserialiseSortData<T>(sortString: string): ColumnSortData<T> {
  try {
    const pairs = sortString.split(' | ');
    const keyValues = pairs.map(pair => {
      const kv = pair.split(' ');
      if (kv.length !== 2) throw new Error(`Bad sort string: ${pair}`);
      return kv as [keyof T, 'ASC' | 'DESC'];
    });
    let data: ColumnSortData<T> = {};
    keyValues.forEach(([k, v]) => {
      data[k] = v;
    });
    return data;
  } catch (error) {
    console.log(error);
    return {};
  }
}

type State = Omit<TableOptions, 'url'> & { preventReRequest: boolean };

type Action =
  | { type: 'set_searchstring'; payload: string; resetSearch: boolean }
  | { type: 'set_sortstring'; payload: string; resetSort: boolean }
  | {
      type: 'set_filterstring';
      payload: string;
      resetFilter: boolean;
      queryParams?: { [k: string]: any };
    }
  | {
      type: 'change_pagesize';
      payload: number;
    }
  | {
      type: 'next_page';
      maxPages: number;
    }
  | {
      type: 'prev_page';
    }
  | { type: 'goto_page'; payload: number; maxPages: number }
  | {
      type: 'data_fetched';
      payload: State;
    }
  | {
      type: 'set_queryparams';
      payload: { [k: string]: any };
    };

function reducer(s: State, action: Action): State {
  const updateByApi = action.type === 'data_fetched';
  let state: State = {
    ...s,
    preventReRequest: updateByApi,
  };

  switch (action.type) {
    case 'set_searchstring':
      return {
        ...state,
        search: action.payload,
        bClearSearch: action.resetSearch,
      };
    case 'set_sortstring':
      return {
        ...state,
        sortBy: action.payload,
        bClearSortBy: action.resetSort,
      };
    case 'set_filterstring':
      return {
        ...state,
        filters: action.payload,
        bClearFilters: action.resetFilter,
        pageNumber: 1,
        queryParams: action.queryParams
          ? action.queryParams
          : state.queryParams,
      };
    case 'set_queryparams':
      return {
        ...state,
        queryParams: action.payload,
      };
    case 'change_pagesize':
      return {
        ...state,
        pageNumber: 1,
        pageSize: action.payload,
      };
    case 'next_page': {
      const nextPage = state.pageNumber ? state.pageNumber + 1 : 1;
      return {
        ...state,
        pageNumber: action.maxPages >= nextPage ? nextPage : state.pageNumber,
      };
    }
    case 'prev_page': {
      const prevPage = state.pageNumber ? state.pageNumber - 1 : 1;
      return { ...state, pageNumber: prevPage > 0 ? prevPage : 1 };
    }
    case 'goto_page': {
      if (action.payload > 0 && action.payload <= action.maxPages) {
        return { ...state, pageNumber: action.payload };
      }
      return state;
    }
    case 'data_fetched':
      return action.payload;
  }
}

export function createUseTable(axiosInstance: AxiosInstance) {
  const preventReRequest = (
    prevConfig: AxiosRequestConfig | null | undefined,
    config: AxiosRequestConfig | null | undefined
  ) => {
    if (!prevConfig || !config) {
      return false;
    }
    let prevent = false;

    /** If ONLY bClearFilters has changed, prevent re-request */
    const prevClearFiltersValue = prevConfig.params.bClearFilters;
    const clearFiltersValue = config.params.bClearFilters;
    if (prevClearFiltersValue === true) {
      if (clearFiltersValue !== true) {
        const prevConfigParsed = fromPairs(
          toPairs(prevConfig.params).filter(([key]) => key !== 'bClearFilters')
        );
        const configParsed = fromPairs(
          toPairs(config.params).filter(([key]) => key !== 'bClearFilters')
        );
        if (JSON.stringify(configParsed) === JSON.stringify(prevConfigParsed)) {
          // only bClearFilters has changed from true -> false/null. no need to re request
          prevent = true;
        }
      }
    }

    return prevent;
  };
  const useFetch = createUseFetch(axiosInstance, preventReRequest);
  return function useTable<T, E>(options: TableOptions) {
    type S = State;
    type A = Action;
    const { url, multiSort, preventRequest, ...initialState } = options;

    const [store, dispatch] = React.useReducer<React.Reducer<S, A>>(reducer, {
      ...initialState,
      preventReRequest: false,
    });
    const [updatedData, setUpdatedData] = React.useState<T[] | null>(null);
    const {
      sortBy,
      filters,
      search,
      bClearFilters,
      bClearSortBy,
      bClearSearch,
      pageSize,
      pageNumber,
      queryParams,
    } = store;

    const config = React.useMemo(
      () => ({
        params: {
          sortBy: sortBy ? sortBy : null,
          filters: filters ? filters : null,
          search: search ?? null,
          bClearFilters: bClearFilters ?? null,
          bClearSearch: bClearSearch ?? null,
          bClearSortBy: bClearSortBy ?? null,
          pageSize: pageSize ?? null,
          pageNumber: pageNumber ?? null,
          ...(queryParams ? queryParams : {}),
        },
      }),
      [
        sortBy,
        pageSize,
        pageNumber,
        filters,
        search,
        bClearFilters,
        bClearSortBy,
        bClearSearch,
        queryParams,
      ]
    );

    const { loading, error, data, reload } = useFetch<TableData<T>, E>(
      url,
      config,
      undefined,
      store.preventReRequest || preventRequest //TODO figure out what this does/did
    );

    // I think this is a valid use of eslint-disable-next-line. Any change to store will
    // trigger a change to data, after it has fetched new data, which is when we want to
    // do the check. If store is in the dependency array, the check will also run whenever
    // store changes, at which point it is out of sync with the up-to-date data that will come.
    React.useEffect(() => {
      if (data) {
        if (
          data.details.filters !== store.filters ||
          data.details.sortBy !== store.sortBy ||
          data.details.pageSize !== store.pageSize ||
          data.details.pageNumber !== store.pageNumber 
        ) {
          dispatch({
            type: 'data_fetched',
            payload: { ...data.details, preventReRequest: true },
          });
        }
      }

      setUpdatedData(null);
      // eslint-disable-next-line
    }, [data]);

    const initialSortBy = data && data.details.sortBy;
    const initialFilters = data && data.details.filters;

    const deserialisedSort = React.useMemo(
      () => (initialSortBy ? deserialiseSortData<any>(initialSortBy) : {}),
      [initialSortBy]
    );

    const deserialisedFilters = React.useMemo(
      () => (initialFilters ? deserialiseFilters(initialFilters) : []),
      [initialFilters]
    );

    const toggleColumnSort = React.useCallback(
      (key: keyof T) => {
        const currentSort = deserialisedSort[key];
        const nextSort =
          currentSort === 'DESC'
            ? 'ASC'
            : currentSort === 'ASC'
            ? 'RESET'
            : 'DESC';
        const newSort: any = multiSort ? { ...deserialisedSort } : {};
        if (multiSort && nextSort === 'RESET') {
          delete newSort[key];
        } else if (nextSort !== 'RESET') {
          newSort[key] = nextSort;
        }
        const sortString = serialiseSortData(newSort);
        dispatch({
          type: 'set_sortstring',
          payload: sortString,
          resetSort: sortString === '',
        });
      },
      [dispatch, deserialisedSort]
    );

    const changeFilter = React.useCallback(
      (key: keyof T, value: Filter[]) => {
        const newFilters =
          deserialiseFilters == null
            ? [{ columnKey: key as string, filters: value }]
            : deserialisedFilters
                .filter(x => x.columnKey !== key)
                .concat([{ columnKey: key as string, filters: value }]);

        dispatch({
          type: 'set_filterstring',
          payload: serializeFilters(newFilters),
          resetFilter: newFilters.length === 0,
        });
      },
      [dispatch, deserialisedFilters]
    );

    const setFilters = React.useCallback(
      (newFilters: Filter[], queryParams?: { [k: string]: any }) => {
        dispatch({
          type: 'set_filterstring',
          payload: serializeFilters(
            newFilters.map(f => ({ columnKey: f.columnKey, filters: [f] }))
          ),
          resetFilter: newFilters.length === 0,
          queryParams,
        });
      },
      []
    );

    const setQueryParams = React.useCallback(
      (queryParams: { [k: string]: any }) => {
        dispatch({
          type: 'set_queryparams',
          payload: queryParams,
        });
      },
      []
    );

    const changeSearch = React.useCallback((searchString: string) => {
      dispatch({
        type: 'set_searchstring',
        payload: searchString,
        resetSearch: searchString === '',
      });
    }, []);

    // Get the last page.
    const lastPage = Math.ceil(
      data ? data.details.resultsCount / data.details.pageSize : 0
    );

    const lastOnPage = data
      ? data.details.pageSize * data.details.pageNumber
      : 0;
    const firstOnPage = data ? lastOnPage - data.details.pageSize + 1 : 0;
    // Display the smallest of the total results count, and the calculated number.
    // This avoids labels with "Showing 11-25 of 18 results" etc.
    const lastOnPageDisplay = data
      ? Math.min(lastOnPage, data.details.resultsCount)
      : 0;

    const changePageSize = React.useCallback(
      (payload: number) => {
        dispatch({ type: 'change_pagesize', payload });
      },
      [dispatch]
    );

    const goToNextPage = React.useCallback(() => {
      dispatch({ type: 'next_page', maxPages: lastPage });
    }, [dispatch, lastPage]);

    const goToPrevPage = React.useCallback(() => {
      dispatch({ type: 'prev_page' });
    }, [dispatch]);

    const goToPage = React.useCallback(
      (page: number) => {
        dispatch({ type: 'goto_page', payload: page, maxPages: lastPage });
      },
      [dispatch, lastPage]
    );

    const updateLocalTable = React.useCallback(
      (idColumn: string, updatedData: Partial<T>[], currentData: T[]) => {
        //find the row that includes the id of the updated row by using idColumn
        //map over currentData, spread the found row, and spread the updatedData onto the corresponding row
        const newData = currentData.map((item: BaseObject) => {
          const found = updatedData.find(
            (d: BaseObject) => d[idColumn] === item[idColumn]
          );

          if (found) {
            return {
              ...item,
              ...found,
            };
          }

          return item;
        });
        setUpdatedData(newData as T[]);
      },
      []
    );

    const hasNextPage = data ? data.details.pageNumber < lastPage : false;
    const hasPrevPage = data ? data.details.pageNumber > 1 : false;

    const formattedData = React.useMemo(
      () =>
        data == null
          ? undefined
          : {
              ...data,
              details: {
                ...data.details,
                listData: updatedData ? updatedData : data.details.listData,
                sortBy: deserialisedSort,
                filters: deserialisedFilters,
                lastPage,
                firstOnPage,
                lastOnPage: lastOnPageDisplay,
                hasNextPage,
                hasPrevPage,
              },
              actions: {
                toggleColumnSort,
                changeFilter,
                setFilters,
                changeSearch,
                changePageSize,
                goToNextPage,
                goToPrevPage,
                goToPage,
                updateLocalTable,
                setQueryParams,
              },
            },
      [
        data,
        changeFilter,
        setFilters,
        changeSearch,
        deserialisedSort,
        deserialisedFilters,
        firstOnPage,
        goToNextPage,
        goToPrevPage,
        goToPage,
        hasNextPage,
        hasPrevPage,
        lastOnPageDisplay,
        lastPage,
        toggleColumnSort,
        changePageSize,
        updateLocalTable,
        updatedData,
        setQueryParams,
      ]
    );

    return {
      loading,
      error,
      data: formattedData,
      reload,
    };
  };
}

export function useTableSelection<T>(
  idColumn: keyof T,
  selectBox?: (
    select: () => void,
    selected: boolean,
    id: number | string
  ) => React.ReactNode
) {
  const [selectedIDs, setSelected] = React.useState<(string | number)[]>([]);

  const rowSelection = React.useMemo(() => {
    const selectRow = (id: string | number) => {
      setSelected(prev =>
        selectedIDs.includes(id) ? prev.filter(i => i !== id) : [...prev, id]
      );
    };

    const selectAll = (ids: (string | number)[]) =>
      setSelected(prev =>
        symmetricDifference(ids, prev).length === 0 ? [] : ids
      );

    return {
      selectedIDs,
      selectRow,
      selectAll,
      idColumn,
      checkbox: selectBox,
    };
  }, [selectedIDs, setSelected, idColumn, selectBox]);

  return rowSelection;
}