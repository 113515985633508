import React from "react";
import ReactDOM from "react-dom";
import { createEpicMiddleware } from "redux-observable";

import "./styles/Main.scss";
import { Location } from "@reach/router";
import { createStore, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";

import { Provider } from "react-redux";
import { rootReducer, rootEpic } from "reducers/rootReducer";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import * as Sentry from "@sentry/browser";

// Sentry.init({
//   dsn: "",
// });

const epicMiddleware = createEpicMiddleware();

const configureStore = () => {
  const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return store;
};

export const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Location>{({ location }) => <App location={location} />}</Location>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
