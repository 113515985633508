import React, { useEffect, useRef } from "react";

export const useOnClickOutside = (handler: (e: MouseEvent) => void) => {
  const ref = useRef<HTMLElement>(null);
  useEffect(() => {
    const listener = (event: MouseEvent) => {
      if (!(event.target instanceof Element)) {
        return;
      }
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("click", listener);
    return () => {
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
  return ref;
};
